@import "~ng-zorro-antd/ng-zorro-antd.aliyun.less";

@primary-color: #0763fb;
@border-radius-base: 4px;


@primary-color-btn-background-color: #004fd6;
@primary-color-btn-border-color: #004fd6;

*{
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, PingFang SC,
  Hiragino Sans GB, Microsoft YaHei, Helvetica Neue, Helvetica, Arial,
  sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;
}

a {
  color: @primary-color !important;
}

a:hover {
  color: #002f77 !important;
}

 .ant-btn-text:hover {
  background-color: #f0f0f0;
}


.ant-btn-primary {
  border-radius: @border-radius-base;
  border-radius: 4px;
  background-color: @primary-color-btn-background-color;
  border-color: @primary-color-btn-border-color;
  color: #fff;
  padding: 3px 20px;
  font-size: 14px;
  font-weight: 400;
  cursor: pointer;
  transition: background-color 0.3s, border-color 0.3s;

  &:hover {
    background-color: #002f77 !important;
    border-color: #002f77 !important;
  }

  &:focus {
    background-color: #002f77 !important;
    border-color: #002f77 !important;
    outline: none !important;
  }

  &:active {
    background-color: #001a43 !important;
    border-color: #001a43 !important;
    color: #fff;
  }

  &:disabled {
    border-color: #002f77 !important;
    background-color: #002f77 !important;
    color: #fff  !important;
    cursor: not-allowed !important;
  }
}

.ant-btn.ant-btn-primary {
  border-radius: @border-radius-base;
  border-radius: 4px;
  background-color: @primary-color-btn-background-color;
  border-color: @primary-color-btn-border-color;
  color: #fff;
  padding: 3px 20px;
  font-size: 14px;
  font-weight: 400;
  cursor: pointer;
  transition: background-color 0.3s, border-color 0.3s;

  &:hover {
    background-color: #002f77 !important;
    border-color: #002f77 !important;
  }

  &:focus {
    background-color: #002f77 !important;
    border-color: #002f77 !important;
    outline: none !important;
  }

  &:active {
    background-color: #001a43 !important;
    border-color: #001a43 !important;
    color: #fff;
  }

  &:disabled {
    border-color: #002f77 !important;
    background-color: #002f77 !important;
    color: #fff  !important;
    cursor: not-allowed !important;
  }
}

.ant-btn[nztype="default"] {
  border-radius: @border-radius-base;
  background-color: #fff !important;
  border-color: #c7d0d9 !important;
  color: #202227 !important;
  font-size: 14px;
  font-weight: 400;
  cursor: pointer;
  transition: background-color 0.3s, border-color 0.3s;


  &:hover {
    background-color: #f1f4fa !important;
    border-color: #c7d0d9 !important;
  }

  &:focus {
    border-color: @primary-color !important;
    // box-shadow: 0 0 3px @primary-color;
  }

  &:active {
    background-color: #e2e7f0 !important;
    border-color: #ccc !important;
  }

  &:disabled {
    background-color: #fff !important;
    border-color: #c7d0d9 !important;
    color: #a6a6a6 !important;
    cursor: not-allowed !important;
  }
}


/* Custom Danger Button Style */
.ant-btn.ant-btn-dangerous {
  border-radius: 4px;
  background-color: #ff4d4f; /* Custom danger background color */
  border-color: #ff4d4f; /* Custom danger border color */
  color: #fff;
  padding: 3px 20px;
  font-size: 14px;
  font-weight: 400;
  cursor: pointer;
  transition: background-color 0.3s, border-color 0.3s;

  &:hover {
    background-color: #d9363e !important; /* Darker shade for hover */
    border-color: #d9363e !important;
  }

  &:focus {
    background-color: #d9363e !important;
    border-color: #d9363e !important;
    outline: none !important;
  }

  &:active {
    background-color: #b71c1c !important; /* Even darker shade for active */
    border-color: #b71c1c !important;
    color: #fff;
  }

  &:disabled {
    background-color: #ff4d4f !important;
    border-color: #ff4d4f !important;
    color: #fff !important;
    cursor: not-allowed !important;
  }
}

/* Default button styling (used for Cancel button) */
.ant-modal-confirm-btns .ant-btn.default-button {
  border-radius: 4px;
  background-color: #fff !important;
  border-color: #c7d0d9 !important;
  color: #202227 !important;
  font-size: 14px;
  font-weight: 400;
  cursor: pointer;
  transition: background-color 0.3s, border-color 0.3s;
}

.ant-modal-confirm-btns .ant-btn.default-button:hover {
  background-color: #f1f4fa !important;
  border-color: #c7d0d9 !important;
}

.ant-modal-confirm-btns .ant-btn.default-button:focus {
  border-color: #1890ff !important;
  box-shadow: 0 0 3px #1890ff;
}

.ant-modal-confirm-btns .ant-btn.default-button:active {
  background-color: #e2e7f0 !important;
  border-color: #ccc !important;
}

.ant-modal-confirm-btns .ant-btn.default-button:disabled {
  background-color: #fff !important;
  border-color: #c7d0d9 !important;
  color: #a6a6a6 !important;
  cursor: not-allowed !important;
}

/* Dangerous button styling (used for Don't Save button) */
.ant-modal-confirm-btns .ant-btn.danger-button {
  border-radius: 4px;
  background-color: #ff4d4f !important;
  border-color: #ff4d4f !important;
  color: #fff !important;
  font-size: 14px;
  font-weight: 400;
  cursor: pointer;
  transition: background-color 0.3s, border-color 0.3s;
}

.ant-modal-confirm-btns .ant-btn.danger-button:hover {
  background-color: #ff7875 !important;
  border-color: #ff7875 !important;
}

.ant-modal-confirm-btns .ant-btn.danger-button:focus {
  border-color: #ff4d4f !important;
  box-shadow: 0 0 3px #ff4d4f;
}

.ant-modal-confirm-btns .ant-btn.danger-button:active {
  background-color: #ff4d4f !important;
  border-color: #ff4d4f !important;
}

.ant-modal-confirm-btns .ant-btn.danger-button:disabled {
  background-color: #fff !important;
  border-color: #ff4d4f !important;
  color: #ff4d4f !important;
  cursor: not-allowed !important;
}

/* Primary button styling (used for Save button) */
.ant-modal-confirm-btns .ant-btn.primary-button {
  border-radius: 4px;
  background-color: #1890ff !important;
  border-color: #1890ff !important;
  color: #fff !important;
  font-size: 14px;
  font-weight: 400;
  cursor: pointer;
  transition: background-color 0.3s, border-color 0.3s;
}

.ant-modal-confirm-btns .ant-btn.primary-button:hover {
  background-color: #40a9ff !important;
  border-color: #40a9ff !important;
}

.ant-modal-confirm-btns .ant-btn.primary-button:focus {
  border-color: #1890ff !important;
  box-shadow: 0 0 3px #1890ff;
}

.ant-modal-confirm-btns .ant-btn.primary-button:active {
  background-color: #096dd9 !important;
  border-color: #096dd9 !important;
}

.ant-modal-confirm-btns .ant-btn.primary-button:disabled {
  background-color: #fff !important;
  border-color: #c7d0d9 !important;
  color: #a6a6a6 !important;
  cursor: not-allowed !important;
}

// Input field styles

.ant-input {
  border-radius: @border-radius-base;
  width: 100%;
  border: 1px solid #ccc;
  transition: border 0.3s, box-shadow 0.3s;
  font-size: 15px;


  &:hover {
    border-color: 1px solid blue;
  }

  &:focus {
    border-color: @primary-color;
    box-shadow: 0 0 3px #03b2cb;
    outline: none;
    outline-offset: 0;
  }
}
.nz-tree-select {
  border-radius: @border-radius-base;
  width: 100%;
  border: 1px solid #ccc;
  transition: border 0.3s, box-shadow 0.3s;
  font-size: 15px;
  min-height: 32px;

  &:hover {
    border-color: 1px solid blue;
  }

  &:focus {
    border-color: @primary-color;
    box-shadow: 0 0 3px #03b2cb;
    outline: none;
    outline-offset: 0;
  }

  // Custom Scrollbar Styling

}

.ant-select {
  width: 100%;

}

.ant-select-dropdown {
  height: auto;
  max-height: 350px ;
  overflow-y: auto; /* Add vertical scroll */
  overflow-x: hidden; /* Hide horizontal scroll */
  padding: revert;
}

.ant-select-item {
  position: relative;
  display: block;
  min-height: 32px;
  color: #202227;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  cursor: pointer;
  transition: background .3s ease;

  &:hover {
    background-color: #ceedff;
  }

  &:active {
    background-color: #e2e7f0 !important; /* Darker background on active */
    color: #004fd6 !important; /* Text color on active */
  }

  &.ant-select-item-option-selected {
    color: #fff;
    font-weight: 500;
    background-color: #004fd6;
  }
}



.cdk-virtual-scrollable {
  height: auto;
  max-height: 350px ;
  overflow-y: auto;
  overflow-x: hidden;
  padding: revert;
  overflow: hidden;
  will-change: scroll-position;
  contain: strict;
  --webkit-overflow-scrolling: touch;
}

.cdk-virtual-scroll-viewport::-webkit-scrollbar {
  width: 7px;
  position: absolute; /* Ensure scrollbar does not affect layout */
}

.cdk-virtual-scroll-viewport::-webkit-scrollbar-track {
  background: transparent;
}

.cdk-virtual-scroll-viewport::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 10px;
}

.cdk-virtual-scroll-viewport::-webkit-scrollbar-thumb:hover {
  background-color: rgba(0, 0, 0, 0.7);
}

.cdk-virtual-scroll-viewport {
  overflow: overlay;
}


.ant-select-item-option-content {
  flex: auto;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  align-items: center;
  display: flex;
}


.ant-select-tree-dropdown {
  padding: revert;
}



// nz tree select
// Tree Switcher
.ant-select-tree-switcher {
  display: none;
}

// Tree Indentation
.ant-select-tree-indent-unit {
  display: inline-block;
  width: 0px; // Minimal indentation
}

// Tree and Checkbox Group
.ant-select-tree,
.ant-select-tree-checkbox-group {
  margin: 0px !important;
  font-size: 15px; // Slightly larger font for readability
  line-height: 1.5715;
  color: rgba(0, 0, 0, 0.85);
  font-variant: tabular-nums;
  font-feature-settings: "tnum";
  list-style: none;
  box-sizing: border-box;
}

// Dropdown Style
.dropdownStyle {
  min-height: 50px;
  max-height: 300px;
  overflow-y: auto; // Vertical scroll for content
  overflow-x: hidden; // Prevent horizontal scroll
}

// Tree Node Styling
.ant-select-tree .ant-select-tree-treenode:first-child {
  border-bottom: 1px dashed #000000; // Separator for the first node
  margin-top: 0px;
  margin-bottom: 3px;
}

// Selector for Tree Nodes
.ant-tree-select .ant-select-selector {
  max-height: 100px;
  overflow-y: hidden; // Enable vertical scroll
  overflow-x: hidden; // Prevent horizontal scroll
}

// Tree Node Content Wrapper
.ant-select-tree-node-content-wrapper {
  background: none !important; // No background to keep it clean
}

// Tree Node Hover and Active States
.ant-select-tree .ant-select-tree-treenode {
  display: flex;
  align-items: center;
  background-color: white;
  transition: background-color 0.3s ease; // Smooth transition for background
  margin: 0px;
  padding: 2px !important;

}

.ant-select-tree .ant-select-tree-treenode:hover {
  background-color: #ceedff; // Light blue on hover
}

// Selected Items in Multi-Select
.ant-select-multiple .ant-select-selection-item {
  display: flex;
  align-items: center;
  background: #f1f4fa; // Light background for selected items
  border-radius: 5px;
  padding: 0 8px;
  line-height: 22px;
  color: #000000;
  cursor: default;
  transition: font-size 0.3s, line-height 0.3s, height 0.3s;
}

// Remove Icon in Multi-Select
.ant-select-multiple .ant-select-selection-item-remove {
  color: rgba(0, 0, 0, 0.45); // Slightly faded remove icon
  font-size: 16px;
  font-weight: 700;
  cursor: pointer;
  margin-left: 3px;
  align-items: center;
  display: flex;
}

.ant-avatar-string {
  position: absolute;
  left: 50%;
  transform-origin: 0 center;
  display: contents;
  font-weight: 600;
  font-size: smaller;
}


.ant-typography + h1.ant-typography, .ant-typography + h2.ant-typography, .ant-typography + h3.ant-typography, .ant-typography + h4.ant-typography, .ant-typography + h5.ant-typography {
  margin-top: 0px;
  margin: 0px;
  padding: 0px;
}



// Global styles for typography
h1.ant-typography,
.ant-typography h1,
h2.ant-typography,
.ant-typography h2,
h3.ant-typography,
.ant-typography h3,
h4.ant-typography,
.ant-typography h4,
h5.ant-typography,
.ant-typography h5,
h6.ant-typography,
.ant-typography h6,
p.ant-typography,
.ant-typography p {
  color: #202227;
  line-height: 1.5;
  // margin-bottom: 0px; // Default margin for spacing
  // margin-top: 0px
  margin: 0px;
  padding: 0px;
}

// Specific styles for different heading levels
h1.ant-typography,
.ant-typography h1 {
  font-size: 26px;
  font-weight: 700;
  line-height: 1.23;
  margin-bottom: 0.5em; // Less margin for h1
}

h2.ant-typography,
.ant-typography h2 {
  font-size: 23px;
  font-weight: 700;
  margin-top: 0px;
}

h3.ant-typography,
.ant-typography h3 {
  margin-bottom: .5em;
  font-weight: 700;
  font-size: 20px;
  line-height: 1.35;
}

h4.ant-typography,
.ant-typography h4 {
  font-size: 16px;
  font-weight: 600;
}

h5.ant-typography,
.ant-typography h5 {
  font-size: 14px;
  font-weight: 600;
}

h6.ant-typography,
.ant-typography h6 {
  font-size: 12px;
  font-weight: 600;
}

// Paragraph styling
p.ant-typography,
.ant-typography p {
  font-size: 14px;
  font-weight: 400;
  color: #202227;
  line-height: 1.6;
}


// Modal

// .ant-modal-header {
//   padding: 7px 15px;
//   color: rgba(0, 0, 0, 0.85);
//   background: #fff;
//   border-bottom: 1px solid #f0f0f0;
//   border-radius: 4px 4px 0 0;
// }

.ant-modal-close-icon {
  font-size: 16px;
  color: #202227;
}

.ant-modal-close-icon:hover {
  background: #f1f4fa;
  padding: 6px;
  border-radius: 4px;
}



// Dropdown
.ant-dropdown-menu {
  position: relative;
  margin: 0;
  padding: 0px 0px;
  // border-radius: 4px 0px;
  text-align: left;
  list-style-type: none;
  background-color: #fff;
  background-clip: padding-box;
  border-radius: 4px;
  outline: none;
  box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
}

.ant-dropdown-menu-item {
  position: relative;
  // padding: 8px 16px;
  cursor: pointer;
  transition: background-color 0.3s, box-shadow 0.3s, transform 0.1s;

  &:hover {
    background-color: #e0f0fb;
  }

  &:active {
    background-color: #b3d4f1; /* Darker background color on click */
    box-shadow: inset 0 3px 6px rgba(0, 0, 0, 0.2); /* Inner shadow on click */
    transform: scale(0.98); /* Slightly shrink the item on click */
  }
}

// message
.ant-message .anticon {
  top: -3px !important;
  font-size: 19px;
}

.ant-page-header{
  border-radius: 4px;
}


// .ant-input-group > .ant-input:last-child, .ant-input-group-addon:last-child {
//   // border: 0px;
//   background: transparent;
//   padding: 0px;
//   height: 32px;
// }

.ant-btn.ant-btn-link:focus,
.ant-btn.ant-btn-link:active {
  border-color: @primary-color !important;
  box-shadow: 0 0 3px @primary-color;
  outline: none; /* Removes the default focus outline */
  border-radius: 0px;
}

.ant-row .gutter-row{
  padding: 6px 10px !important;
}

// card

.ant-card{
  border-radius: 5px;
}

.ant-alert-with-description .ant-alert-message {
  display: block;
  margin-bottom: 4px;
  color: rgba(0, 0, 0, 0.85);
  font-size: 16px;
  margin-top: 7px;
}


/* Modal Header Style */
.ant-modal-header {
  background: #ffffff;
  border-bottom: 1px solid #e8e8e8; /* Subtle border for separation */
  border-radius: 6px 6px 0px 0px; /* Rounded corners at the bottom */
  box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.25); /* Bottom-side shadow */
}


/* Modal Footer Style */
.ant-modal-footer {
  text-align: end;
  background: #ffffff;
  border-top: 1px solid #e8e8e8; /* Subtle border for separation */
  border-radius: 0 0 8px 8px; /* Rounded corners at the bottom */
  box-shadow: 0px -2px 15px rgba(0, 0, 0, 0.15); /* Top-side shadow */
}



/* Override the disabled state background and text color */
.ant-select-disabled,
.ant-select-disabled .ant-select-selector,
.ant-select-disabled .ant-select-selection-item {
  background-color: #f1f4fa !important;
  color: #202227 !important;
}



.ant-select-disabled .ant-select-arrow {
  color: #202227 !important;
}


.ant-drawer.ant-drawer-open .ant-drawer-mask {
  height: 100%;
  opacity: 0.3;
  transition: none;
  animation: antdDrawerFadeIn 0.3s cubic-bezier(0.23, 1, 0.32, 1);
  pointer-events: auto;
}

.cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
  opacity: 0.3;
}


.ant-pagination-item a {
  display: block;
  padding: 0 0px;
  color: rgba(0, 0, 0, 0.85);
  transition: none;
}




.ant-tabs > .ant-tabs-nav .ant-tabs-nav-list, .ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-list {
  position: relative;
  display: flex;
  transition: transform 0.3s;
  gap: 2px;
}


