:root {
    /* Font family */
    --heading-font-family: Arial, sans-serif; /* Heading font family */
    --paragraph-font-family: Arial, sans-serif; /* Paragraph font family */
  
    /* Background colors */
    --body-background-color: #f8f9fa; /* Body background color */
    --container-background-color: #ffffff; /* Container background color */
    --header-background-color: #007bff; /* Header background color */
    --footer-background-color: #6c757d; /* Footer background color */
    
    /* Text colors */
    --content-text-color: #333333; /* Text color for content */
    --header-text-color: #ffffff; /* Text color for header */
    --footer-text-color: #ffffff; /* Text color for footer */
    --headings-color: #333; /* Headings color */
  
    
    /* Font sizes */
    --h1-font-size: 1.5rem; /* H1 font size */
    --h2-font-size: 1rem; /* H2 font size */
    --h3-font-size: 0.90rem; /* H3 font size */
    --h4-font-size: 0.80rem; /* H4 font size */
    --h5-font-size: 0.70rem; /* H5 font size */
    --h6-font-size: 0.50rem; /* H6 font size */
    --paragraph-font-size: 1rem; /* Paragraph font size */
    
    /* Font weights */
    --heading-font-weight: 750; /* Heading font weight */
    --paragraph-font-weight: normal; /* Paragraph font weight */

    --paragraph-Line-height: 1.5;
    
    /* Line height */
    --paragraph-line-height-H1: 24px; /* Paragraph line height */
    --Headings-line-height-H1: 0.8; /* Paragraph line height */
    
    /* Advanced features */
    --transition-duration: 0.3s; /* Transition duration */
    --primary-color: #007bff; /* Primary color */
    --secondary-color: #6c757d; /* Secondary color */
    --border-radius: 5px; /* Border radius */
    --box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Box shadow */
  }


  h1{
    font-family: var(--heading-font-family);
    font-size: var(--h1-font-size);
    color: var(--headings-color);
    font-weight: var(--heading-font-weight);
  }
  
  h2 {
    font-family: var(--heading-font-family);
    font-size: var(--h2-font-size);
    font-weight: var(--h2-font-weight);
    color: var(--headings-color);
    font-weight: var(--heading-font-weight);

  }
  
  h3 {
    font-family: var(--heading-font-family);
    font-size: var(--h3-font-size);
    font-weight: var(--h3-font-weight);
    color: var(--headings-color);
    font-weight: var(--heading-font-weight);

  }
  
  h4 {
    font-family: var(--heading-font-family);
    font-size: var(--h4-font-size);
    font-weight: var(--h4-font-weight);
    color: var(--headings-color);
    font-weight: var(--heading-font-weight);

  }
  
  h5 {
    font-family: var(--heading-font-family);
    font-size: var(--h5-font-size);
    font-weight: var(--h5-font-weight);
    color: var(--headings-color);
    font-weight: var(--heading-font-weight);

  }
  
  h6 {
    font-family: var(--heading-font-family);
    font-size: var(--h6-font-size);
    font-weight: var(--h6-font-weight);
    color: var(--headings-color);
    font-weight: var(--heading-font-weight);

  }
  

