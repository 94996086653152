/* src/styles.css */
@import "~bootstrap/dist/css/bootstrap.min.css";
/* @import "~ng-zorro-antd/ng-zorro-antd.min.css"; */
@import "~@progress/kendo-theme-fluent/dist/all.css";
@import "src/app/shared/GlobalCss/global.css";
@import 'ckeditor5/ckeditor5.css';

@import "~ng-zorro-antd/ng-zorro-antd.aliyun.css";


body,
h1,
h2,
h3,
h4,
h5,
h6,
p,
a {
  margin: 0px;
  padding: 0px;
}

body {
  font-family: "Roboto", sans-serif;
  margin: 0px;
  padding: 0px;
}




::ng-deep .ant-message .anticon {
  top: -3px !important;
  font-size: 19px;
}


/* 
.wrapper {
  margin-bottom: 20px;
} */


.cityDropdown {
  font-size: 10px;
  font-weight: 700;
  text-transform: capitalize;
  color: #3e3e3e;
  margin-bottom: 3px;
}
.formField {
    width: 100%;
    height: 32px;
    border: 1px solid #ccc;
    transition: border 0.3s, box-shadow 0.3s;
    font-size: 16px;
}
.formField:hover {
    border: 1px solid blue;
}
.formField:focus {
    border-color: #0763fb;
    box-shadow: 0 0 3px #03b2cb;
    outline: none;
    outline-offset: 0;
}
.formField.error {
  border-color: red;
  box-shadow: 0 0 3px rgb(239, 116, 116); /* Add a red box shadow for the error state */
}
.formField:focus.error {
    box-shadow: 0 0 3px rgb(241, 130, 130); /* Add a red box shadow for the error state */
    outline: none;
    outline-offset: 0;
}
  /* ::ng-deep .ant-picker {
    border-radius: 6px !important;
  } */
nz-input-number{
    width: 100%;
}
.famly-inn {
    color: #202227;
    font-family: -apple-system,BlinkMacSystemFont,Segoe UI,PingFang SC,Hiragino Sans GB,Microsoft YaHei,Helvetica Neue,Helvetica,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol;
}
.pira-label{
    font-size: 14px;
    letter-spacing: 0.2px;
    margin-top: 1px;
    color: #202227;
}
.ListViewJobname {
    color: rgb(78, 85, 95);
    font-size: 12px;
    font-weight: 400;
    text-align: left;
    font-size: 12px;
    padding-top: 0px;
    font-family: -apple-system,BlinkMacSystemFont,Segoe UI,PingFang SC,Hiragino Sans GB,Microsoft YaHei,Helvetica Neue,Helvetica,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol;
}
.CloseIconCursor{
  cursor: pointer;
}
textarea {
  resize: none;
}
/*****************************************************
	Custom styling example bellow.
*****************************************************/
.iti {
  display: block !important;
  margin-bottom: 20px;
}
.iti .dropdown-menu.country-dropdown {
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
  border-color: #c7cace;
  margin-top: -1px;
}
.iti .iti__country-list {
  box-shadow: none;
  font-size: 14px;
  margin-left: 0;
  width: 100%;
  height: 270px;
}
.iti__flag-container.open + input {
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
}
.iti .search-container input {
  font-size: 14px;
  border-color: #c7cace;
  border-radius: 0;
  padding: 5px 10px;
}
.iti .search-container input:focus {
  outline: none;
}
@media screen and (max-width: 479px) {
  .iti .iti__country-list {
    width: 100vw;
  }
}
ngx-intl-tel-input input {
  border-style: solid;
  border-width: 1px;
  border-color: #ccc;
  width: 100%;
  height: 32px;
  border-radius: 4px;
}

::ng-deep ngx-intl-tel-input input::-webkit-input-placeholder {
  color: #bac2c7;
}
::ng-deep ngx-intl-tel-input input:-ms-input-placeholder {
  color: #bac2c7;
}
::ng-deep ngx-intl-tel-input input::-ms-input-placeholder {
  color: #bac2c7;
}
::ng-deep ngx-intl-tel-input input::placeholder {
  color: #bac2c7;
}
::ng-deep ngx-intl-tel-input input[disabled] {
  background-color: #e5eaf1;
}
.para-lable {
  font-size: 14px;
  letter-spacing: 0.2px;
  margin-top: 1px;
  font-weight: bold;
  color: #202227;
}
.sub-text {
  font-weight: 700;
  color: #202227;
  font-size: 12px;
  position: relative;
  letter-spacing: 0.2px;
  white-space: normal;
  margin-top: 7px;
  margin-bottom: 3px;
  color: #252227;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, PingFang SC,
    Hiragino Sans GB, Microsoft YaHei, Helvetica Neue, Helvetica, Arial,
    sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;
}
nz-select {
  width: 100%;
}
nz-tree-select {
  width: 100%;
}

.none-buttom {
  color: #004fd6;
  background: transparent;
  border: 1px solid transparent;
  border-radius: 4px;
}
.active {
  background-color: #c4fae2;
  color: #005c35;
  border-radius: 10px;
  font-weight: bold;
  margin-left: 5px;
}
.inactive {
  background-color: #c7d0d9;
  border-radius: 10px;
  font-weight: bold;
  color: #202227;
  opacity: 90%;
}
.invitepending {
  background-color: #fbe9b1;
  border-radius: 10px;
  font-weight: bold;
  color: #653200;
  opacity: 90%;
}
.archived {
  background-color: #c7d0d9;
  border-radius: 10px;
  font-weight: bold;
  color: #202227;
  opacity: 90%;
}
/* ::ng-deep nz-alert .ant-alert-info {
  background-color: #ffffff;
} */
nz-card {
  border-radius: 8px;
}
/* Add CSS styles */
.loader-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 9999; /* Ensure the loader is on top of other content */
}

.spinner-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(213, 209, 209, 0.5);
  z-index: 9999;
}

.spinner-wrapper .ant-spin {
  display: flex;
  align-items: center;
  justify-content: center;
}

.spinner-wrapper .ant-spin-spinning {
  position: static;
  display: inline-block;
  opacity: 1;
}


  .leaflet-touch .leaflet-bar a {
  width: 30px;
  height: 30px;
  line-height: 15px !important;
}


/* .leaflet-popup-close-button{
  position: absolute;
  top: 0;
  right: 0;
  border: none;
  text-align: center;
  width: 30px;
  height: 18px;
  font: 30px / 16px Tahoma, Verdana, sans-serif;
  color: #757575;
  text-decoration: none;
  background: transparent;
} */

.leaflet-container a.leaflet-popup-close-button {
  position: absolute;
  top: 0;
  right: 0;
  border: none;
  text-align: center;
  width: 36px;
  height: 24px;
  font: 30px / 14px Tahoma, Verdana, sans-serif;
  color: #757575;
  text-decoration: none;
  background: transparent;

}